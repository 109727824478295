// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, persistor } from './store';

import { reindexCards, buttonColors, toggleRandomState, 
         setCurrentSet, shuffleAndModifyKeys, 
         initializeSpeech, setCurrentSubMenu,
         setSpeakerState } from './actions';

import "./AppStyles.css";
import "./MenuStyles.css";
// import flashcarddata from "./data.json"
import iconShuffled from "./assets/img/shuffled.png"
import iconUserList from "./assets/img/star.png"
import iconHome from "./assets/img/home.png"
import iconSpeaker from "./assets/img/speaker.png"
import iconNoSpeaker from "./assets/img/nospeaker.png"

// export default function SubMenu({ setOptions }) {
export default function SubMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flashcarddata = useSelector((state: Record<string, any>) => state.flashcard.flashcarddata);

  const userList = useSelector((state: RootState) => state.app.userList);
  const randomState = useSelector((state: RootState) => state.app.randomState);
  const currentSubMenu = useSelector((state: RootState) => state.app.currentSubMenu);
  const speaker = useSelector((state: any) => state.app.speakerState) ?? true;
  const setName = currentSubMenu;

  useEffect(() => {
    if (setName == null) {
      navigate("/");
    }
  }, [setName, navigate]);

  const selectSet = (subsetName: any) => {
    const dataset = (flashcarddata[setName as keyof typeof flashcarddata] as any)[subsetName];
    if (dataset) {
      const carddata = randomState ? shuffleAndModifyKeys(dataset) : dataset;
      dispatch(setCurrentSet(carddata));
      navigate("/cards");
    } else {
      console.error(`Invalid set name: ${subsetName}`);
    }
  };

  const selectUserList = () => {
    initializeSpeech();

    const carddata = randomState ? shuffleAndModifyKeys(userList) : reindexCards(userList);
    dispatch(setCurrentSet(carddata));
//    dispatch(setCurrentSubMenu("MainMenu"));  // remove this so up navigates back to submenu
    navigate("/cards");
  };

  const handleShuffleChange = () => {
    dispatch(toggleRandomState());
  };

  function handleSpeakerClick(event:any) {
    event.stopPropagation();  //prevent card from being flipped
    dispatch(setSpeakerState());
  }

  function returnHome() {
    navigate("/");
  }

  return (
    <div className="submenu">
      <div className="menu">練習國字</div>

      <div className="topbody">
        <button onClick={returnHome} className="homeButton">
          <img src={iconHome} alt="空" />
        </button>
        <button onClick={handleShuffleChange} className={`toggle-button ${randomState ? '隨機順序' : '正常秩序'}`}>
          <img src={iconShuffled} alt="隨機順序" />
        </button>
        <button onClick={handleSpeakerClick} className="speakerButton">
          <img src={speaker ? iconSpeaker : iconNoSpeaker} alt="說" />
        </button>
        <button onClick={selectUserList} className="userListButton">
          <img src={iconUserList} alt="星" />
        </button>
      </div>

      <div className="nav3fr">
        {setName && flashcarddata[setName as keyof typeof flashcarddata] && Object.keys(flashcarddata[setName as keyof typeof flashcarddata]).map((subsetName,index) => (
          <button key={subsetName} 
                  onClick={() => selectSet(subsetName)}
                  style={{ backgroundColor: buttonColors[index % buttonColors.length] }}>
            {subsetName}
          </button>
        ))}
      </div>
    </div>
  );
}
