// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

export const TOGGLE_RANDOM_STATE = 'TOGGLE_RANDOM_STATE';
export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION';
export const SET_CURRENT_SET = 'SET_CURRENT_SET';
export const ADD_TO_USER_LIST = 'ADD_TO_USER_LIST';
export const REMOVE_FROM_USER_LIST = 'REMOVE_FROM_USER_LIST';
export const SET_CURRENT_SUBMENU = 'SET_CURRENT_SUBMENU';
export const SET_SPEAKER_STATE = 'SET_SPEAKER_STATE';
export const RECEIVE_FLASHCARDDATA = 'RECEIVE_FLASHCARDDATA';
export const PURGE_FLASHCARDDATA = 'PURGE_FLASHCARDDATA';
export const SET_CARD_SIDE = 'SET_CARD_SIDE';

export const buttonColors = [ '#772651', '#43286E', '#6D2937', '#2A3274', 
                              '#693C29', '#277155', '#77642B', '#3E6A2B',
                              '#AC295C', '#7A26B1', '#B03A2E', '#3532AC' ];


export const toggleRandomState = () => ({
  type: TOGGLE_RANDOM_STATE,
});

export const setSpeakerState = () => ({
  type: SET_SPEAKER_STATE,
});

export const updateScrollPosition = (data: any) => ({
  type: UPDATE_SCROLL_POSITION,
  payload: data,
});

export const setCurrentSet = (data: any) => ({
  type: SET_CURRENT_SET,
  payload: data,
});

export const addToUserList = (card: any) => ({
  type: ADD_TO_USER_LIST,
  payload: card,
});

export const removeFromUserList = (card: any) => ({
  type: REMOVE_FROM_USER_LIST,
  payload: card,
});

export const setCurrentSubMenu = (submenu: any) => ({
  type: SET_CURRENT_SUBMENU,
  payload: submenu,
});

export const reindexCards = (cardData: any) => {
  const cardList = [...cardData];
  const indexedCards = cardList.map((card, index) => ({
    ...card, id: String(index + 1).padStart(5, '0'),
  }));
  return indexedCards;
}

export const receiveFlashcardData = (flashcarddata: any) => ({
  type: RECEIVE_FLASHCARDDATA,
  payload: flashcarddata,
});

export const purgeFlashcardData = () => ({
  type: PURGE_FLASHCARDDATA,
});

export const setCardSide = (side: any) => ({
  type: SET_CARD_SIDE,
  payload: side,
});

export const shuffleAndModifyKeys = (cardData:any) => {
  // Shuffle the cards using Fisher-Yates algorithm
  const shuffledCards = [...cardData];
  for (let i = shuffledCards.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledCards[i], shuffledCards[j]] = [shuffledCards[j], shuffledCards[i]];
  }

  // Update the 'id' field to be consecutive counts starting at 1
  // const updatedCards = shuffledCards.map((card, index) => ({
  //   ...card,
  //   id: String(index + 1).padStart(5, '0'),
  // }));

  return reindexCards(shuffledCards);
};


export const initializeSpeech = () => {
    const message = new SpeechSynthesisUtterance();
    message.text = " ";
    message.volume = 1; // Volume range = 0 - 1
    message.rate = 0.8; // Speed of the text read , default 1
    message.lang = "zh-TW"; // Language, default 'zh-TW'
    speechSynthesis.speak(message);
}


